<template>
  <nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
      <button
        @click.prevent="toggleMenu"
        class="navbar-toggler navbar-toggler--logo align-self-center"
        type="button"
        data-toggle="minimize"
      >
        <span class="mdi mdi-menu"></span>
      </button>
      <a class="navbar-brand brand-logo" href="/">
        <img src="/assets/images/logo.svg" alt="logo" />
      </a>
      <a class="navbar-brand brand-logo-mini" href="/">
        <img src="/assets/images/logo-mini.svg" alt="logo" />
      </a>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-stretch">
      <button
        @click.prevent="toggleMenu"
        class="navbar-toggler align-self-center"
        type="button"
        data-toggle="minimize"
      >
        <span class="mdi mdi-menu"></span>
      </button>

      <ul class="navbar-nav navbar-nav-right">
        <li class="nav-item dropdown">
          <a
            class="nav-link count-indicator dropdown-toggle"
            id="notificationDropdown"
            href="#"
            data-toggle="dropdown"
          >
            <i class="mdi mdi-bell-outline"></i>
            <span v-if="hasUnread" class="count-symbol bg-danger"></span>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
            aria-labelledby="notificationDropdown"
          >
            <h6 class="h6 p-3 mb-0 d-inline-block mr-5">
              Notificaciones
            </h6>
            <a href="#" v-if="hasUnread" @click.prevent="makeAllRead" class="d-inline-block ml-4">
              Limpiar
            </a>
            <div class="dropdown-divider"></div>

            <a v-for="notification in notifications" :key="notification.id" class="dropdown-item preview-item">
              <div class="preview-thumbnail">
                <div class="preview-icon bg-gradient-info">
                  <i class="mdi mdi-calendar"></i>
                </div>
              </div>
              <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                <div class="align-self-end" @click.prevent="markAsRead(notification)">
                  <i class="mdi mdi-close"></i>
                </div>
                <a :href="notification.action_url">
                  <h6 class="preview-subject font-weight-normal mb-1">{{ notification.title }}</h6>
                </a>
                <p class="text-gray ellipsis mb-0">{{ notification.body }}</p>
              </div>
            </a>
          </div>
        </li>
        <li class="nav-item nav-logout d-none d-lg-block">
          <b-dropdown
            class="nav-link"
            toggle-class="tms-dropdown-logout"
            right
          >
            <template #button-content>
              <i class="mdi mdi-power"></i>
            </template>
            <b-dropdown-item @click.prevent="onLogout">
              Salir
            </b-dropdown-item>
          </b-dropdown>
        </li>
      </ul>

      <button
        @click.prevent="toggleMenu"
        class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
        type="button"
        data-toggle="offcanvas"
      >
        <span class="mdi mdi-menu"></span>
      </button>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TopBarLayout',
  data () {
    return {
      modalOptions: {
        id: 'modalLogout',
        msg: '¿Seguro que desea cerrar la sesión?',
        title: 'Cerrando sesión',
        okVariant: 'danger',
        okTitle: 'Sí, estoy seguro',
        cancelTitle: 'No, cancelar',
        dialogClass: 'tms-modal',
        noCloseOnBackdrop: true,
        hideBackdrop: true,
        centered: true
      }
    }
  },
  mounted () {
    this.getNotifications()
  },
  computed: {
    ...mapGetters({
      notifications: 'Notification/notifications'
    }),
    hasUnread () {
      return this.notifications.length > 0
    },
    total () {
      return this.notifications.length
    }
  },
  methods: {
    ...mapActions({
      makeRead: 'Notification/makeRead',
      makeAllRead: 'Notification/makeAllRead',
      getNotifications: 'Notification/get'
    }),
    markAsRead (notification) {
      this.makeRead(notification)
    },
    markNotificationsRead () {
      this.makeAllRead()
    },
    toggleMenu () {
      this.$store.dispatch('toggleMenu')
    },
    onLogout () {
      this.$bvModal.msgBoxConfirm(this.modalOptions.msg, this.modalOptions)
        .then(value => {
          if (value) {
            this.$router.push({ name: 'logout' })
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style scoped>

</style>
