<template>
  <li v-if="user" class="nav-item">
    <a href="#" class="nav-link" :class="{ 'text-muted': isPushEnabled }" :disabled="pushButtonDisabled || loading" @click.prevent="initPushNotification">
      <i class="mdi mdi-bell menu-icon"></i>
      <span class="menu-title" :class="{ 'text-muted': isPushEnabled }"> Notificaciones</span>
    </a>
  </li>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/messaging'
import 'firebase/analytics'

export default {
  name: 'WebPushSubscription',
  data: () => ({
    isPushEnabled: false,
    pushButtonDisabled: true
  }),
  mounted () {
    this.validateSubscriptionStatus()
  },
  methods: {
    ...mapActions({
      postSubscribe: 'User/subscribe',
      postUnsubscribe: 'User/unsubscribe'
    }),
    ...mapMutations({
      SET_LOADING_STATUS: 'SET_LOADING_STATUS'
    }),
    enablePushNotification () {
      if (!('serviceWorker' in navigator)) {
        this.$swal('Esta función aún no esta disponible en éste equipo o navegador.', '', 'info')
        return
      }
      navigator.serviceWorker.ready.then(() => this.initPushNotification())
    },
    validateSubscriptionStatus () {
      const _self = this
      const firebaseConfig = {
        apiKey: 'AIzaSyAdMq41efS-_CyiHGwJ3r7BSKGQtEnHU38',
        authDomain: 'licen-web-push.firebaseapp.com',
        databaseURL: 'https://licen-web-push.firebaseio.com',
        projectId: 'licen-web-push',
        storageBucket: 'licen-web-push.appspot.com',
        messagingSenderId: '838543656361',
        appId: '1:838543656361:web:9dc838e38651a127ed70b8',
        measurementId: 'G-GCH83P2RRW'
      }
      navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
        serviceWorkerRegistration.pushManager.getSubscription()
          .then(function (subscription) {
            if (!subscription) {
              return
            }
            _self.isPushEnabled = true
            _self.pushButtonDisabled = false
          })
          .catch(function (err) {
            console.log('Error during getSubscription()', err)
          })
      })
      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig)
        firebase.analytics()
      }
      const messaging = firebase.messaging()
      messaging.onMessage(function (payload) {
        _self.$swal({
          toast: true,
          icon: 'success',
          position: 'top-end',
          title: payload.notification.title,
          showConfirmButton: false,
          showCancelButton: false,
          timerProgressBar: true,
          timer: 3000,
          onOpen: (toast) => {
            toast.addEventListener('mouseenter', _self.$swal.stopTimer)
            toast.addEventListener('mouseleave', _self.$swal.resumeTimer)
          }
        })
      })
      this.updateSubscriptionToken()
    },
    initPushNotification () {
      const _self = this
      const messaging = firebase.messaging()
      if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
        this.$swal('Las notificaciones no están disponible en éste equipo o navegador.', '', 'info')
        return
      }
      if (Notification.permission === 'denied') {
        this.$swal('Las notificaciones están bloqueadas por el usuario', '', 'info')
        return
      }
      if (!('PushManager' in window)) {
        this.$swal('Las notificaciones push no están disponible en éste equipo o navegador.', '', 'info')
        return
      }
      navigator.serviceWorker.ready.then(registration => {
        registration.pushManager.getSubscription()
          .then(subscription => {
            this.pushButtonDisabled = false
            if (!subscription) {
              Notification
                .requestPermission()
                .then(function () {
                  return messaging.getToken()
                })
                .then(function (token) {
                  _self.updateSubscription(token)
                })
            }
            this.isPushEnabled = true
            this.updateSubscriptionToken()
          })
          .catch(e => {
            console.log('error getting subscription', e)
          })
      })
    },
    updateSubscriptionToken () {
      const _self = this
      const messaging = firebase.messaging()
      messaging.onTokenRefresh(() => {
        messaging.getToken()
          .then((refreshedToken) => {
            _self.updateSubscription(refreshedToken)
          })
          .catch((err) => {
            console.log('Unable to retrieve refreshed token ', err)
          })
      })
    },
    updateSubscription (token) {
      const data = {
        fcm_token: token
      }
      this.SET_LOADING_STATUS(true)
      this.postSubscribe(data)
        .then(() => { this.SET_LOADING_STATUS(false) })
        .catch(e => {
          console.log('Error during getSubscription()', e)
        })
    },
    removeSubscription () {
      this.SET_LOADING_STATUS(true)
      this.postUnsubscribe()
        .then(() => {
          this.isPushEnabled = false
          this.pushButtonDisabled = true
          this.SET_LOADING_STATUS(false)
        })
        .catch(e => {
          this.SET_LOADING_STATUS(false)
          console.log('Error unsubscribing user', e)
        })
    },
    togglePush () {
      if (this.isPushEnabled) {
        this.removeSubscription()
      } else {
        this.enablePushNotification()
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'Authentication/loggedIn',
      loading: 'isLoading'
    })
  }
}
</script>
