<template>
  <div class="container-scroller" :class="menuStatus ? 'sidebar-icon-only' : ''">
    <top-bar></top-bar>
    <div class="container-fluid page-body-wrapper">
      <sidebar></sidebar>
      <div class="main-panel">
        <div v-if="hasContentSlot" class="content-wrapper">
          <slot name="content"></slot>
        </div>
        <slot v-if="hasFullContentSlot" name="content-full-width"></slot>
        <footer-layout></footer-layout>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/SidebarMenuLayout'
import TopBar from '@/components/TopBarLayout'
import FooterLayout from '@/components/FooterLayout'
import { mapGetters, mapMutations } from 'vuex'
import Echo from 'laravel-echo'
import axios from 'axios'

export default {
  name: 'BaseLayout',
  computed: {
    ...mapGetters({
      loggedIn: 'Authentication/loggedIn'
    }),
    hasContentSlot () {
      return !!this.$slots.content
    },
    hasFullContentSlot () {
      return !!this.$slots['content-full-width']
    },
    menuStatus () {
      return this.$store.getters.menuStatus
    },
    isLoading () {
      return this.$store.getters.isLoading
    }
  },
  created () {
    if (this.loggedIn) {
      this.listen()
    }
  },
  methods: {
    ...mapMutations({
      APPEND_NOTIFICATION: 'Notification/APPEND_NOTIFICATION',
      MARK_AS_READ: 'Notification/MARK_AS_READ',
      MARK_ALL_READ: 'Notification/MARK_ALL_READ'
    }),
    listen () {
      const self = this
      window.Pusher = require('pusher-js')
      window.Pusher.logToConsole = false
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_PUSHER_KEY,
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        forceTLS: true,
        authorizer: (channel, options) => {
          return {
            authorize: (socketId, callback) => {
              axios.post(process.env.VUE_APP_BASE_URL + 'api/broadcasting/auth', {
                socket_id: socketId,
                channel_name: channel.name
              }, { withCredentials: true })
                .then(response => {
                  callback(false, response.data)
                })
                .catch(error => {
                  callback(true, error)
                })
            }
          }
        }
      })
      window.Echo.private('App.Models.User.' + this.loggedIn)
        .notification((notification) => {
          self.APPEND_NOTIFICATION(notification)
        })
        .listen('NotificationRead', ({ notificationId }) => {
          self.MARK_AS_READ(notificationId)
        })
        .listen('NotificationReadAll', () => {
          self.MARK_ALL_READ()
        })
    }
  },
  components: {
    Sidebar,
    TopBar,
    FooterLayout
  }
}
</script>
