<template>
  <footer class="footer">
    <div class="d-sm-flex justify-content-center justify-content-sm-between">
      <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Version 2.0.1</span>
      <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Powered by <a href="https://veratsys.com/">Veratsys.</a></span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterLayout'
}
</script>

<style scoped>

</style>
