<template>
  <nav
    class="sidebar sidebar-offcanvas"
    :class="menuStatus ? 'active' : ''"
    id="sidebar"
  >
    <ul class="nav">
      <b-nav-item
        :to="{ name: 'home' }"
        :class="validateCollapseNav(['home']) ? 'active' : ''"
      >
        <i class="mdi mdi-home menu-icon menu-icon"></i>
        <span class="menu-title">
          Dashboard
        </span>
      </b-nav-item>

      <b-nav-item
        :to="{ name: 'records' }"
        v-if="checkAccessPermissions.includes('records-menu')"
        :class="validateCollapseNav(['records']) ? 'active' : ''"
      >
        <i class="mdi mdi-folder-multiple menu-icon"></i>
        <span class="menu-title">
          Expedientes
        </span>
      </b-nav-item>

      <li
        class="nav-item"
        :class="validateCollapseNav(['agenda', 'agenda_calendar']) ? 'active' : ''"
      >
        <a
          class="nav-link"
          href="#"
          v-b-toggle="'calendar-menu'"
        >
          <i class="mdi mdi-calendar menu-icon"></i>
          <span class="menu-title">
            Agenda
          </span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse
          id="calendar-menu"
          accordion="sidebar-menu"
          :visible="validateCollapseNav(['agenda', 'agenda_calendar'])"
        >
          <ul class="nav flex-column sub-menu">
            <b-nav-item
              :to="{ name: 'agenda' }"
              v-if="checkAccessPermissions.includes('agenda-menu')"
            >
              Eventos
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'agenda_calendar' }"
              v-if="checkAccessPermissions.includes('agenda-menu')"
            >
              Calendario
            </b-nav-item>
          </ul>
        </b-collapse>
      </li>

      <li
        class="nav-item d-none d-md-inline-block"
        :class="validateCollapseNav(['bills', 'invoices', 'payments', 'expenses']) ? 'active' : ''"
      >
        <a
          class="nav-link"
          href="#"
          v-b-toggle="'bills-menu'"
        >
          <i class="mdi mdi-coin menu-icon"></i>
          <span class="menu-title">
            Finanzas
          </span>
          <i class="menu-arrow"></i>
        </a>
        <b-collapse
          id="bills-menu"
          accordion="sidebar-menu"
          :visible="validateCollapseNav(['bills', 'invoices', 'payments', 'expenses'])"
        >
          <ul class="nav flex-column sub-menu">
            <b-nav-item
              :to="{ name: 'bills' }"
              v-if="checkAccessPermissions.includes('bills-menu')"
            >
              Gastos de la Firma
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'invoices' }"
              v-if="checkAccessPermissions.includes('invoices-menu')"
            >
              Facturacion
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'payments' }"
              v-if="checkAccessPermissions.includes('payments-menu')"
            >
              Recibos de Ingresos
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'expenses' }"
              v-if="checkAccessPermissions.includes('expenses-menu')"
            >
              Gastos de Expedientes
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'finance_report' }"
              v-if="checkAccessPermissions.includes('invoices-menu')"
            >
              Reporte Financiero
            </b-nav-item>
          </ul>
        </b-collapse>
      </li>

      <b-nav-item
        :to="{ name: 'clients' }"
        v-if="checkAccessPermissions.includes('clients-menu')"
        :class="validateCollapseNav(['clients']) ? 'active' : ''"
      >
        <i class="mdi mdi-account-multiple menu-icon"></i>
        <span class="menu-title">
          Clientes
        </span>
      </b-nav-item>

      <b-nav-item
        :to="{ name: 'providers' }"
        v-if="checkAccessPermissions.includes('providers-menu')"
        :class="validateCollapseNav(['providers']) ? 'active' : ''"
      >
        <i class="mdi mdi-account-multiple menu-icon"></i>
        <span class="menu-title">
          Proveedores
        </span>
      </b-nav-item>

      <li
        class="nav-item"
        :class="validateCollapseNav(['users', 'courts', 'instances', 'roles']) ? 'active' : ''"
      >
        <a
          class="nav-link"
          href="#"
          v-b-toggle="'config-menu'"
        >
          <i class="mdi mdi-settings menu-icon"></i>
          <span class="menu-title">
            Configuración
          </span>
          <i class="menu-arrow"></i>
        </a>

        <b-collapse
          id="config-menu"
          accordion="sidebar-menu"
          :visible="validateCollapseNav(['users', 'courts', 'instances', 'roles'])"
        >
          <ul class="nav flex-column sub-menu">
            <b-nav-item
              :to="{ name: 'record_print_labels' }"
              v-if="checkAccessPermissions.includes('records-menu')"
            >
              Generador de Labels
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'users' }"
              v-if="checkAccessPermissions.includes('users-menu')"
            >
              Usuarios
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'courts' }"
              v-if="checkAccessPermissions.includes('courts-menu')"
              class="d-none d-md-inline-block"
            >
              Tribunales
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'instances' }"
              v-if="checkAccessPermissions.includes('instances-menu')"
              class="d-none d-md-inline-block"
            >
              Instancias
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'roles' }"
              v-if="checkAccessPermissions.includes('roles-menu')"
              class="d-none d-md-inline-block"
            >
              Roles
            </b-nav-item>
          </ul>
        </b-collapse>
      </li>

      <web-push-subscription></web-push-subscription>
    </ul>
  </nav>
</template>

<script>
import WebPushSubscription from '@/components/WebPushSubscription'
import { mapGetters } from 'vuex'

export default {
  name: 'SidebarMenuLayout',
  computed: {
    ...mapGetters('Authentication', ['permissions']),
    checkAccessPermissions () {
      return this.permissions
    },
    menuStatus () {
      return this.$store.getters.menuStatus
    },
    currentPage () {
      return this.$route.name
    }
  },
  components: {
    WebPushSubscription
  },
  methods: {
    validateCollapseNav (pages) {
      if (pages.includes(this.currentPage)) {
        return true
      }

      return false
    }
  }
}
</script>
